body {
  background-color: #f2f2f2;
  height: 100%;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
}

.i:visited,
.i {
  color: inherit;
  text-decoration: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  box-shadow: 0 1px 3px 0 #D4D4D5, 0 0 0 1px #D4D4D5;
}

.leftHeader {
  display: flex;
  align-items: center;
  gap: 3px;
}

.aboutButtonHeader,
.projectsButtonHeader,
.contactButtonHeader {
  background-color: white;
  border-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
  border-radius: 25px;
}

@media only screen and (max-width: 600px) {

  .aboutButtonHeader,
  .projectsButtonHeader,
  .contactButtonHeader {
    margin-left: 1px;
  }

  .header {
    padding-right: 5px;
  }
}

.profileicon {
  width: 30px;
  height: 30px;
  max-width: 100%;
  border-radius: 50%;
}

/* App.js */
span.intro {
  font-weight: bold;
}


.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.layout {
  width: 850px;
  max-width: 90%;
  background: white;
  box-shadow: 0 1px 3px 0 #D4D4D5, 0 0 0 1px #D4D4D5;
  border-radius: 5px;
  z-index: 1;
}

.profilepic {
  width: 150px;
  max-width: 50%;
  border-radius: 50%;
  z-index: 2;
  margin-bottom: -100px;
}

.name {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  font-size: 10px;
}

.badges {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .badges {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px;
    margin-bottom: -70px;
    gap: 2.5px;
  }
}

.languages {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(34, 36, 38, .1);
  border-bottom: 1px solid rgba(34, 36, 38, .1);
}

.aboutme {
  /* padding-top: 10px; */
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.275;
}

.buttons {
  display: flex;
  justify-content: center;
}

.aboutbutton:hover,
.projectsbutton:hover,
.contactbutton:hover,
.aboutButtonHeader:hover,
.projectsButtonHeader:hover,
.contactButtonHeader:hover {
  background: #DCDCDC;
}

.aboutbutton:active,
.projectsbutton:active,
.contactbutton:active,
.aboutButtonHeader:active,
.projectsButtonHeader:active,
.contactButtonHeader:active {
  background: white;
}

.aboutbutton,
.projectsbutton,
.contactbutton {
  background-color: white;
  border-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
  border-radius: 5px;
}

/* Projects.jsx */
.projectspage,
.contactpage {
  display: flex;
  flex-direction: column;
}

.projectspost,
.contactmelayout {
  width: 850px;
  max-width: 90%;
  background: white;
  box-shadow: 0 1px 3px 0 #D4D4D5, 0 0 0 1px #D4D4D5;
  border-radius: 5px;
  align-self: center;
  margin: 20px;
}

.title {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(34, 36, 38, .1);
  margin: 0;
  padding-top: 10px;
}

.date {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin: 0;
  padding-top: 10px;
}

.projectstext {
  padding: 10px;
}

.contactme,
.links {
  display: flex;
  justify-content: center;
  text-align: center;
}

.links {
  font-size: 40px;
  padding-top: 40px;
  padding-bottom: 20px;
}